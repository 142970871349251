
<template>
  <div class="container">

    <b-modal
      size="lg"
      ref="edit-form"
      hide-footer
      >
      <b-form>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('ONBOARDING.GROUP_NAME')">

              <b-form-input
                v-model="local_item.name"
                ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$refs['edit-form'].hide()"
              ref="saveButton"
            />
          </b-col>
        </b-row>
      </b-form>


    </b-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'OnboardingGroupModal',

  props: ['item'],
  emits: ['create', 'update'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      handler: function (val) {
        if (val) {
          this.local_item = { ...val };
        }
      },
      deep: true
    }
  },

  computed: {
    creating() {
      return !this.local_item || !this.local_item.id;
    },
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    show() {
      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    async on_submit() {

      if (this.creating) {
        this.$emit('create', this.local_item);
      }
      else {
        this.$emit('update', this.local_item);
      }

      this.hide();
    },
  },

  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }

  },

  data() {
    return {

      local_item: {},

    };
  }
};

</script>


<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
